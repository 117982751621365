import * as React from "react";
import TwitterLogo from "../../images/twitter-logo.inline.svg";

class BlocTwitter extends React.Component {
  render() {
    const { twitterUrl, firstName } = this.props;

    return (
      <>
        {twitterUrl ? (
          <p>
            <a
              className="social-network no-underline"
              href={twitterUrl}
              target="_blank"
              rel="noreferrer"
            >
              suivez {firstName ? firstName : "nous"} sur Twitter
              <TwitterLogo className="mt-2 mb-3 inline-block w-5 fill-yellow pl-2 lg:w-8" />
            </a>
          </p>
        ) : (
          <></>
        )}
      </>
    );
  }
}
export default BlocTwitter;
