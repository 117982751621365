import * as React from "react";
import InstagramLogo from "../../images/instagram-logo.inline.svg";

class BlocInstagram extends React.Component {
  render() {
    const { instagramUrl, firstName } = this.props;
    return (
      <p>
        <a
          className="social-network no-underline"
          href={instagramUrl}
          target="_blank"
          rel="noreferrer"
        >
          suivez {firstName ? firstName : "nous"} sur Instagram
          <InstagramLogo className="mt-2 mb-3 inline-block w-5 fill-yellow pl-2 lg:w-8" />
        </a>
      </p>
    );
  }
}

export default BlocInstagram;
