import * as React from "react";
import { useStaticQuery, graphql } from "gatsby";
import _map from "lodash/map";
import BlocImage from "./bloc-image";

const BlocNext = () => {
  const data = useStaticQuery(graphql`
    query allStrapiQueenQueryNext {
      allStrapiQueen(sort: { fields: sortOrder, order: DESC }) {
        nodes {
          mainPhoto {
            url
          }
          slug
          firstName
          name
        }
      }
    }
  `);
  const imageList = _map(data.allStrapiQueen.nodes, (d) => (
    <div className="block w-1/6 md:hidden">
      <a href={`/queen/${d.slug}`}>
        <BlocImage src={d.mainPhoto.url} />
      </a>
    </div>
  ));

  return (
    <div className="flex flex-wrap justify-center gap-2 pt-4 pb-2 last:justify-center">
      {imageList}
    </div>
  );
};

export default BlocNext;
