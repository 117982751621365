import * as React from "react";
import { graphql } from "gatsby";
import BlocTitle from "../components/elements/bloc-title";
import BlocActivities from "../components/elements/bloc-activities";
import BlocImage from "../components/elements/bloc-image";
import BlocContent from "../components/elements/bloc-content";
import BlocInstagram from "../components/elements/bloc-instagram";
import BlocTwitter from "../components/elements/bloc-twitter";
import TextBackground from "../components/elements/text-background";
import Layout from "../components/layout";
import DragRace from "../images/drag-race-logo.png";
import { Helmet } from "react-helmet";
import BlocNext from "../components/elements/bloc-next";
import { imgConvertToWebP } from "../utils";

const queen = ({ data }) => {
  return (
    <Layout>
      <Helmet>
        <meta charSet="utf-8" />
        <title>{data.strapiQueen.SEO.metaTitle}</title>
        <meta
          name="description"
          content={data.strapiQueen.SEO.metaDescription}
        />

        {/* <link rel="canonical" href="http://mysite.com/example" /> */}
      </Helmet>
      <div className="font-gotham text-white">
        <div className="-space-y-2 bg-yellow pt-2 pb-1 text-center">
          <a
            href={data.strapiQueen.instagramUrl}
            target="_blank"
            rel="noreferrer"
            className="no-underline hover:text-pink"
          >
            <BlocTitle
              styleName="text-xl lg:text-3xl font-gotham-ultra"
              content={data.strapiQueen.name}
            />
          </a>
          <BlocActivities
            styleName="text-xl lg:text-3xl mt-8"
            content={data.strapiQueen.activities}
          />
        </div>
        <div>
          <div className="mx-auto flex max-w-screen-xl flex-wrap justify-center bg-black lg:flex-nowrap lg:px-24">
            <BlocImage
              className="object-fit lg:w-1/2 lg:object-contain"
              src={data.strapiQueen.mainPhoto.url}
            />
            <div className="text-tiny pl-3 pt-8 lg:w-1/2 lg:text-lg">
              <div className="grow-0 self-start px-8 pb-4 font-gotham-ultra uppercase text-yellow lg:ml-2 lg:px-4 lg:px-6 lg:pb-2">
                <BlocInstagram
                  instagramUrl={data.strapiQueen.instagramUrl}
                  firstName={data.strapiQueen.firstName}
                />
                <BlocTwitter
                  twitterUrl={data.strapiQueen.twitterUrl}
                  firstName={data.strapiQueen.firstName}
                />
                {data.strapiQueen.mentionDragRaceVisible ? (
                  <p className="self-center">
                    retrouvez <span>{data.strapiQueen.firstName}</span> à la{" "}
                    {""}
                    <span className="hidden xl:inline-block"> drag race</span>
                    <img
                      alt="background"
                      className="mt-2 mb-3 inline-block w-16 pl-2 xl:mb-5 xl:w-28 "
                      src={imgConvertToWebP(DragRace).src}
                    />
                  </p>
                ) : (
                  ""
                )}
              </div>
              <BlocContent
                className="flex grow flex-col justify-center h-full px-8 pb-14 text-sm text-white"
                content={data.strapiQueen.content.data.content}
              />
            </div>
          </div>
          <TextBackground
            opacity="40"
            imgUrl={data.strapiQueen.backgroundImg.url}
          >
            <div className="z-30 mx-auto flex max-w-screen-xl flex-wrap pb-10">
              <BlocImage
                className="object-fit z-30 lg:w-1/2 lg:w-1/2 lg:object-contain lg:pt-12"
                src={data.strapiQueen.secondaryImg.url}
              />
              <div className="z-30 flex grow items-center space-y-2 lg:w-1/2">
                <BlocContent
                  className="py-8 px-8 lg:leading-loose"
                  content={data.strapiQueen.achievements.data.achievements}
                  list={true}
                />
              </div>
            </div>
          </TextBackground>
        </div>
        <div className="relative z-30 max-h-[70vh]">
          <BlocNext />
        </div>
      </div>
    </Layout>
  );
};

export const query = graphql`
  query strapiQueenQuery($slug: String!) {
    strapiQueen(slug: { eq: $slug }) {
      name
      achievements {
        data {
          achievements
        }
      }
      activities {
        name
      }
      instagramUrl
      twitterUrl
      mainPhoto {
        height
        width
        url
        alternativeText
      }
      secondaryImg {
        height
        width
        url
        alternativeText
      }
      backgroundImg {
        height
        width
        url
        alternativeText
      }
      content {
        data {
          content
        }
      }
      firstName
      mentionDragRaceVisible
      SEO {
        metaTitle
        metaDescription
      }
    }
  }
`;
export default queen;
