import * as React from "react";
import _map from "lodash/map";

export default class BlocActivities extends React.Component {
  render() {
    const activities = this.props.content;
    const activitiesList = _map(activities, (d, i) => (
      <h2 className="inline" key={d.id}>
        {d.name}
        {activities.length - 1 !== i ? <span className="px-2">/</span> : ""}
      </h2>
    ));
    return <div>{activitiesList}</div>;
  }
}
